import { withAuthenticator } from '@aws-amplify/ui-react'
import React, { useEffect, useState } from 'react'
import { Lesson } from '@chrisdunne-com/chrisdunne-types'
import { Modal } from 'reactstrap'
import { deleteData, getData } from '../../utils/server'
import { Footer } from '../../components/footer'
import { Header } from '../../components/header'
import { Sidebar } from '../../components/sidebar'
import { Table } from '../../components/table'
import AddLessonModal from './lessons-modal'
import { daysUntilReview } from '../../utils/resources'
import { SocialButton } from '../../components/social'

function TheLessons() {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [modalLesson, setModalLesson] = useState<Lesson>()
  const [lessons, setLessons] = useState<any[]>([])

  useEffect(() => {
    if (!success && !error) {
      getData('/lesson').then(
        (result: any) => {
          setSuccess(true)
          setLessons(result)
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  })

  function tog_backdrop(item?: Lesson) {
    setModalLesson(undefined)
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()

    if (item) {
      setModalLesson(item)
    }
  }

  function put_lesson(lesson: Lesson) {
    if (lessons.some((x) => x.slug === lesson.slug)) {
      const item = lessons.findIndex((x) => x.slug === lesson.slug)
      lessons[item] = lesson
    } else {
      setLessons([...lessons, lesson])
    }
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  function delete_item(slug: string) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this lesson?') === true) {
      deleteData('/lesson', slug).then(
        (result: any) => {
          setLessons(lessons.filter((lesson) => lesson.slug !== slug))
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  }

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <h4 className="font-size-18">Lessons</h4>
            <button
              type="button"
              className="btn btn-success float-end"
              onClick={() => {
                tog_backdrop()
              }}
              data-toggle="modal"
              style={{ marginRight: '50px' }}
            >
              Add New
            </button>
            <Modal
              size="lg"
              isOpen={modal_backdrop}
              toggle={() => {
                tog_backdrop()
              }}
              backdrop={'static'}
              id="staticBackdrop"
            >
              <AddLessonModal
                setmodal_backdrop={setmodal_backdrop}
                put_lesson={put_lesson}
                lesson={modalLesson}
              />
            </Modal>
            <Table columns={['🔗', 'Social', 'Course', 'Title', 'Review']}>
              {lessons.map((item: Lesson, count) => (
                <tr
                  key={count}
                  className={
                    daysUntilReview(new Date(item.lastUpdated)) < 10
                      ? 'bg-danger'
                      : ''
                  }
                >
                  <td>
                    <a
                      href={`${process.env.REACT_APP_CHRIS_DUNNE_URL}/course/${item.course}/lesson/${item.slug}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bx-link-external"></i>
                    </a>
                  </td>
                  <td>
                    <SocialButton
                      text={`Check out this lesson, "${item.title}"! ${item.description}`}
                      url={`https://chrisdunne.com/course/${item.course}/lesson/${item.slug}`}
                      hashtags="chrisdunne"
                    />
                  </td>
                  <td>{item.course}</td>
                  <td>{item.title}</td>
                  <td>{daysUntilReview(new Date(item.lastUpdated))} days</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        tog_backdrop(item)
                      }}
                      data-toggle="modal"
                    >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-light btn-sm"
                      onClick={() => {
                        delete_item(item.slug)
                      }}
                      data-toggle="modal"
                    >
                      <i className="bx bx-x"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </Table>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export const Lessons = withAuthenticator(TheLessons, {
  hideSignUp: true,
  loginMechanisms: ['email'],
})
